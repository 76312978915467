import React, { Component } from 'react';

import { Layout, SEO } from '../../components';
import { validateArray } from '../../components/utilities/functions';

import './index.scss';

class IndexPage extends Component {

	render() {
		const { pageContext } = this.props;
		const {
			uid='', locale="",
			url, seo, heading, description, address_section, form_aside, thank_you_message, form_heading } = (pageContext.node) || {};

		const extension = {
			contenttype:(pageContext?.contentType || ''),
			uid:uid,
			locale:locale
		}

		return (

			<Layout pageURL={url}>
				<SEO pageURL={url} hasMarketo {...seo} extension={extension}/>
				<div className='contactUS-wrapper'>
					<div className='container container--980'>
						{
							(heading || description) &&
							<div className='top-content col-12 col-768-7 text-center'>
								{
									heading &&
									<h1 className='heading'>{heading}</h1>
								}
								{
									description &&
									<div dangerouslySetInnerHTML={{ __html: description }} />
								}
							</div>
						}
						<div className='contact-form-row'>
							<div className='row noGutter'>
								<div className='col-12 col-768-6 form-col success-col'>
									<h3>{form_heading || 'Get in touch'}</h3>
									<form id="mktoForm_1031">
										<div className='form-loader'></div>
									</form>
									<div id="confirmform" className='thankyou-msg text-center'>
										<h3 className='msg'><strong> {thank_you_message || 'Thank you! Your message has been successfully sent.'}</strong></h3>
									</div>
								</div>
								{/* Form section END */}
								{
									form_aside &&
									(form_aside.title || form_aside.description) &&
									<div className='col-12 col-768-6 aside-col'>
										{
											form_aside.title &&
											<h3>{form_aside.title}</h3>
										}
										{
											form_aside.description &&
											<div dangerouslySetInnerHTML={{ __html: form_aside.description }} />
										}
									</div>
								}
							</div>
						</div>
						{
							validateArray(address_section) &&
							<div className='addresses-wrapper'>
								{
									address_section.map((item, index) =>
										<React.Fragment key={index}>
											{
												item.heading &&
												<h3 className='text-center'>{item.heading}</h3>
											}
											<div className='row address-section'>
												{
													validateArray(item.addresses) &&
													item.addresses.map((adrress, idx) =>
														<div className='col col-12 col-768-4 text-center addr-col' key={idx}>
															{
																adrress.title &&
																<h4>{adrress.title}</h4>
															}
															{
																adrress.description &&
																<div dangerouslySetInnerHTML={{ __html: adrress.description }} />
															}
														</div>
													)
												}
											</div>
										</React.Fragment>
									)
								}
							</div>
						}
					</div>
				</div>
			</Layout>
		)
	}
}

export default IndexPage;
